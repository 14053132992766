<template>
  <hcc-modal
    name="add-template-modal"
    :title="$t('campaigns.data-bank.templates.add-template')"
    @confirm="handleConfirm"
    @cancel="clear"
    @opened="focusRefs"
    :confirmDisableButton="!saveEnabled"
  >
    <div class="add-template__modal">
      <hcc-input
        :placeholder="$t('common.name')"
        ref="templateName"
        v-model.trim="name"
        :label="$t('common.name')"
        class="add-template__row"
        @blur="$v.name.$touch()"
        :error="$v.name.$error"
        :errorMessage="$t('campaigns.data-bank.templates.error')"
      />

      <hcc-select
        v-model="typeInput"
        option-label="type"
        track-by="id"
        :options="options"
        :label="$t('common.type')"
        :placeholder="$t('campaigns.data-bank.templates.select-type')"
        custom-class="add-template__row"
      />
      <hcc-select
        v-model="categoryInput"
        track-by="id"
        option-label="name"
        :options="categories"
        :label="$t('configuration.categories.header')"
        :placeholder="$t('configuration.categories.header')"
        custom-class="add-template__row"
      />
      <hcc-textarea
        v-model.trim="description"
        name="template_add"
        :placeholder="$t('campaigns.data-bank.templates.description')"
        :label="$t('campaigns.data-bank.templates.description')"
        class="add-template__row"
        ref="templateTextarea"
      />
      <div class="add-template__container" @click="addParameter()">
        <plus-circle-icon class="add-template__icon" />
        <span class="add-template__text"
          >{{ $t("campaigns.data-bank.templates.add-parameter") }}
        </span>
      </div>
    </div>
  </hcc-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import EventBus from '@/eventBus';
import { CREATE_TEMPLATE } from '@/eventTypes';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';
import HccTextarea from '@/components/shared/HccTextarea/index.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import TemplateCategories from './categories.json';

const validName = (value) => {
  const regex = new RegExp(/^[a-zA-Z0-9_]*$/g);
  return regex.test(value);
};

export default {
  components: {
    HccModal, HccSelect, HccTextarea, HccInput,
  },
  data() {
    return {
      options: [
        { id: 1, type: this.$t('campaigns.data-bank.templates.text') },
        { id: 2, type: this.$t('campaigns.data-bank.templates.image') },
      ],
      typeInput: '',
      description: '',
      name: '',
      categoryInput: '',
      categories: [],
    };
  },
  computed: {
    parametersCount() {
      return ((this.description || '').match(/\{\{\d+\}\}/gi) || []).length;
    },
    saveEnabled() {
      return this.typeInput !== '' && this.description !== ''
        && this.name !== '' && this.categoryInput !== '' && !this.$v.$invalid;
    },
  },
  mounted() {
    this.templateCategories();
  },
  validations: {
    name: {
      required,
      valid: name => validName(name),
    },
  },
  methods: {
    handleConfirm() {
      EventBus.$emit(CREATE_TEMPLATE, {
        type: this.typeInput.type,
        text: this.description,
        element_name: this.name,
        category: this.categoryInput.name,
      });
      this.clear();
    },
    clear() {
      this.$v.$reset();
      this.typeInput = '';
      this.description = '';
      this.name = '';
      this.categoryInput = '';
    },
    addParameter() {
      const num = this.parametersCount + 1;
      this.description = `${this.description} {{${num}}}`;
      this.$refs.templateTextarea.focus();
    },
    focusRefs() {
      this.$refs.templateName.focus();
    },
    templateCategories() {
      this.categories = TemplateCategories.data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/settings/dataBank/_templates_tab_data_bank.scss";
</style>
